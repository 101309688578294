export const getCentreImage = (
    metadata: string,
    categoryId?: string,
): string | undefined => {
    type CentreMetadata = {
        centreImages?: { url: string; tags: string[] }[];
        centreImageURLs?: string[];
    };
    const m: CentreMetadata = JSON.parse(metadata);
    if (!m.centreImages) {
        return m.centreImageURLs?.[0];
    }
    const firstImage = m.centreImages[0]?.url;
    if (categoryId) {
        return (
            m.centreImages.find((i) => i.tags.includes(categoryId))?.url ||
            firstImage
        );
    }
    return firstImage;
};

export const getDirections = (
    latitude: number,
    longitude: number,
    orgName?: string,
    gMapsPlaceId?: string,
): { wazeLink: string; gMapsLink: string } => {
    let wazeLink = `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;
    let gMapsLink = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    if (gMapsPlaceId) {
        wazeLink += `&place=${gMapsPlaceId}`;
        // coordinates will not allow place ID to be used
        gMapsLink = `https://www.google.com/maps/dir/?api=1&destination=${orgName}&destination_place_id=${gMapsPlaceId}`;
    }
    return { wazeLink, gMapsLink };
};
