import documentAdvertisement from "@/assets/advertisement.json";
import { components } from "@/slices";
import { SliceZone } from "@prismicio/react";
import { useFlag } from "@unleash/proxy-client-react";

type AdvertisementBannerV2Props = {
    page: string;
    centreNames?: string | string[];
    categoryNames?: string | string[];
    divider?: boolean;
};
//currently for pages we have:'showVenueProfile','showCheckout',
//'showVenuesListing','showSearch','showBookingDetails'.'showBookingConfirmation'

const AdvertisementBannerV2 = ({
    page,
    centreNames,
    categoryNames,
    divider = false,
}: AdvertisementBannerV2Props): JSX.Element => {
    const isShowPage =
        documentAdvertisement.data.slices[0]?.primary?.advertisements.some(
            (ad) => ad?.[page] === true,
        );
    const advertisementNew = useFlag("feature.advertisement");
    if (!advertisementNew) return <div />;
    if (!isShowPage) return <div />;

    return (
        <SliceZone
            slices={documentAdvertisement.data.slices}
            components={components}
            defaultComponent={components.default_slice}
            context={{ page, centreNames, categoryNames, divider }}
        />
    );
};

export default AdvertisementBannerV2;
